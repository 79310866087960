import React from "react";
import { Tooltip, TooltipProps, makeStyles } from "@fluentui/react-components";
import { SparkleFilled } from "@fluentui/react-icons";
import { PincitesColor } from "./PincitesTheme";

const useStyles = makeStyles({
  sparkleIcon: {
    scale: 1.3,
    color: PincitesColor.BRAND_BLUE,
    ":hover": {
      color: PincitesColor.BRAND_BLUE_HOVER,
    },
  },
});

interface PincitesTooltipProps extends Partial<TooltipProps> {
  content: string;
  children: React.JSX.Element;
}

function PincitesTooltip({
  content,
  children,
  ...tooltipProps
}: PincitesTooltipProps): React.JSX.Element {
  return (
    <Tooltip
      withArrow
      content={content}
      relationship="description"
      {...tooltipProps}
    >
      {children}
    </Tooltip>
  );
}
function AISparkleAndToolTip(): React.JSX.Element {
  const styles = useStyles();

  return (
    <PincitesTooltip content={"This content is used for your AI suggestions"}>
      <SparkleFilled className={styles.sparkleIcon} />
    </PincitesTooltip>
  );
}

export { PincitesTooltip, AISparkleAndToolTip };
