import React from "react";
import { Margin, getCSStylesFromMargins } from "./Margin";
import { makeStyles, mergeClasses } from "@fluentui/react-components";

export enum PincitesFlexboxDirection {
  VERTICAL = "VERTICAL",
  HORIZONTAL = "HORIZONTAL",
}

export enum PincitesFlexboxAlignItems {
  START = "START",
  CENTER = "CENTER",
  END = "END",
}

export enum PincitesFlexboxJustifyContent {
  START = "START",
  CENTER = "CENTER",
  END = "END",
  SPACE_BETWEEN = "SPACE_BETWEEN",
}

export enum PincitesFlexboxGap {
  GAP_1 = "GAP_1",
  GAP_2 = "GAP_2",
  GAP_4 = "GAP_4",
  GAP_8 = "GAP_8",
  GAP_12 = "GAP_12",
  GAP_16 = "GAP_16",
  GAP_20 = "GAP_20",
  GAP_36 = "GAP_36",
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    overflowWrap: "anywhere",
  },
  fullWidth: {
    width: "fill-available",
  },
});

export default function PincitesFlexbox({
  id,
  margin = [],
  direction = PincitesFlexboxDirection.HORIZONTAL,
  alignItems = PincitesFlexboxAlignItems.START,
  justifyContent = PincitesFlexboxJustifyContent.START,
  gap,
  children,
  fullWidth = true,
  customClassName = "",
  ariaLabel,
}: {
  id?: string;
  children: React.ReactNode;
  margin?: Margin[];
  direction?: PincitesFlexboxDirection;
  alignItems?: PincitesFlexboxAlignItems;
  justifyContent?: PincitesFlexboxJustifyContent;
  gap?: PincitesFlexboxGap;
  fullWidth?: boolean;
  customClassName?: string;
  ariaLabel?: string;
}): React.JSX.Element {
  const styles = useStyles();
  const style = {
    ...getCSSStyleFromFlexboxDirection(direction),
    ...getCSSStyleFromAlignItems(alignItems),
    ...getCSStylesFromMargins(margin),
    ...getCSSStyleFromJustifyContent(justifyContent),
    ...(gap ? getCSSStyleFromGap(gap) : {}),
  };
  const classStyles = mergeClasses(
    styles.root,
    customClassName,
    fullWidth ? styles.fullWidth : "",
  );

  return (
    <div id={id} style={style} className={classStyles} aria-label={ariaLabel}>
      {children}
    </div>
  );
}

function getCSSStyleFromFlexboxDirection(
  direction: PincitesFlexboxDirection,
): React.CSSProperties {
  switch (direction) {
    case PincitesFlexboxDirection.VERTICAL:
      return {
        flexDirection: "column",
      };
    case PincitesFlexboxDirection.HORIZONTAL:
      return {
        flexDirection: "row",
      };
  }
}

function getCSSStyleFromAlignItems(
  alignItems: PincitesFlexboxAlignItems,
): React.CSSProperties {
  switch (alignItems) {
    case PincitesFlexboxAlignItems.START:
      return { alignItems: "flex-start" };
    case PincitesFlexboxAlignItems.CENTER:
      return { alignItems: "center" };
    case PincitesFlexboxAlignItems.END:
      return { alignItems: "flex-end" };
  }
}

function getCSSStyleFromJustifyContent(
  justifyContent: PincitesFlexboxJustifyContent,
): React.CSSProperties {
  switch (justifyContent) {
    case PincitesFlexboxJustifyContent.START:
      return { justifyContent: "flex-start" };
    case PincitesFlexboxJustifyContent.CENTER:
      return { justifyContent: "center" };
    case PincitesFlexboxJustifyContent.END:
      return { justifyContent: "flex-end" };
    case PincitesFlexboxJustifyContent.SPACE_BETWEEN:
      return { justifyContent: "space-between" };
  }
}

function getCSSStyleFromGap(gap: PincitesFlexboxGap): React.CSSProperties {
  const value = parseInt(gap.split("_").pop() ?? "");
  return {
    gap: `${value}px`,
  };
}
