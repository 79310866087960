import React from "react";
import { Button, makeStyles, shorthands } from "@fluentui/react-components";
import {
  PincitesButtonSize,
  PincitesButtonType,
  getAppearanceForButtonText,
  getButtonSize,
} from "./PincitesButtonUtils";
import { Margin, getCSStylesFromMargins } from "./Margin";
import { PincitesTooltip } from "./PincitesTooltip";
import { capitalizeFirstLetters } from "../../utils/textUtils";
import {
  PincitesSpinner,
  PincitesSpinnerAppearance,
  PincitesSpinnerSize,
} from "./PincitesSpinner";
import { PincitesColor } from "./PincitesTheme";
import { Maybe } from "../../types/Types";

const useStyles = makeStyles({
  destructiveButton: {
    // Apply common styles to various states
    "&:not(:disabled), &:active:hover, &[disabled], &[disabled]:hover, &[disabled]:active:hover":
      {
        backgroundColor: PincitesColor.RED_DANGER_BACKGROUND,
        color: PincitesColor.WHITE_ALWAYS,
        ...shorthands.borderColor(PincitesColor.WHITE_ALWAYS),
      },
    // Additional specific styles
    "&:not(:disabled)": {
      cursor: "pointer",
    },
    "&[disabled]": {
      cursor: "not-allowed",
    },
    "&:active:hover": {
      backgroundColor: PincitesColor.RED_ACTIVE,
    },
  },
});

export default function PincitesButton({
  showSpinner = false,
  label,
  onClick,
  buttonType,
  icon,
  buttonSize = PincitesButtonSize.MEDIUM,
  isDisabled = false,
  ariaLabel,
  tooltipContent,
  margin = [],
}: {
  showSpinner?: boolean;
  label?: string;
  onClick?: () => void;
  buttonType: PincitesButtonType;
  icon?: React.ReactElement | null;
  buttonSize?: PincitesButtonSize;
  isDisabled?: boolean;
  href?: string;
  ariaLabel?: string;
  tooltipContent?: Maybe<string>;
  margin?: Margin[];
}): React.JSX.Element {
  const styles = useStyles();

  const buttonProps = {
    onClick,
    icon: icon || null,
    size: getButtonSize(buttonSize),
    appearance: getAppearanceForButtonText(buttonType),
    "aria-label": ariaLabel ?? label,
    disabled: isDisabled,
    style: getCSStylesFromMargins(margin),
    className:
      buttonType === PincitesButtonType.DESTRUCTIVE
        ? styles.destructiveButton
        : "",
  };

  const buttonContent = showSpinner ? (
    <PincitesSpinner
      id="button-spinner"
      size={PincitesSpinnerSize.TINY}
      appearance={
        buttonType === PincitesButtonType.DESTRUCTIVE
          ? PincitesSpinnerAppearance.INVERTED
          : PincitesSpinnerAppearance.PRIMARY
      }
    />
  ) : (
    // Capitalizes first letter of every word
    label && capitalizeFirstLetters(label)
  );

  // Adds tooltip around button if one exists
  return tooltipContent ? (
    <PincitesTooltip content={tooltipContent}>
      <Button {...buttonProps}>{buttonContent}</Button>
    </PincitesTooltip>
  ) : (
    <Button {...buttonProps}>{buttonContent}</Button>
  );
}
