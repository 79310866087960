// function to capitalize the first letter of each word in a string, except for conjunctions and prepositions
export const capitalizeFirstLetters = (str: string) => {
  const exceptions = [
    "is",
    "the",
    "on",
    "in",
    "a",
    "an",
    "and",
    "of",
    "to",
    "with",
  ];
  return str.replace(/\b\w+\b/g, (word) => {
    return exceptions.includes(word.toLowerCase())
      ? word
      : word.charAt(0).toUpperCase() + word.slice(1);
  });
};
