import { tokens } from "@fluentui/react-components";

export const PincitesShadow = {
  SHADOW2: tokens.shadow2,
  SHADOW4: tokens.shadow4,
  SHADOW8: tokens.shadow8,
  SHADOW16: tokens.shadow16,
  SHADOW28: tokens.shadow28,
  SHADOW64: tokens.shadow64,
};

// https://react.fluentui.dev/?path=/docs/theme-border-radii--page
export const PincitesBorderRadius = {
  NONE: tokens.borderRadiusNone,
  SMALL: tokens.borderRadiusSmall,
  MEDIUM: tokens.borderRadiusMedium,
  LARGE: tokens.borderRadiusLarge,
  EXTRA_LARGE: tokens.borderRadiusXLarge,
  CIRCLE: tokens.borderRadiusCircular,
};

export const PincitesColor = {
  BRAND_BLUE: tokens.colorBrandForeground1,
  BRAND_BLUE_BACKGROUND: tokens.colorBrandBackground2,
  BRAND_BLUE_BACKGROUND_LIGHTER: tokens.colorNeutralBackground1Hover,
  BRAND_BLUE_HOVER: tokens.colorNeutralForeground2BrandHover,
  BLUE_SELECTED: tokens.colorNeutralStrokeAccessibleSelected,
  GRAY_BORDER: tokens.colorNeutralStencil1,
  GRAY_HOVER: tokens.colorNeutralStroke1Hover,
  GRAY_DISABLED: tokens.colorNeutralForegroundDisabled,
  GRAY_TEXT: tokens.colorNeutralForeground4,
  GREEN_BACKGROUND: tokens.colorPaletteGreenBackground3,
  GREEN_TEXT: tokens.colorPaletteLightGreenForeground1,

  // Option to respect the user's system preferences
  NEUTRAL: tokens.colorNeutralForeground1, // Dark on light mode for text
  NEUTRAL_BACKGROUND: tokens.colorNeutralBackground1, // White on light mode, dark gray on dark mode
  NEUTRAL_BACKGROUND_2: tokens.colorNeutralBackground2, // Light gray on light mode, darker gray on dark mode
  WHITE_ALWAYS: tokens.colorNeutralForegroundOnBrand, // White on both light and dark mode

  RED: "red" /* This is brighter than any of the reds in the palette; used for Redlines */,
  RED_ACTIVE: tokens.colorStatusDangerBorderActive,
  RED_BACKGROUND: tokens.colorPaletteRedBackground3,
  RED_DANGER_BACKGROUND: tokens.colorStatusDangerBackground3,
  SUBTLE_BACKGROUND: tokens.colorNeutralBackgroundAlpha,
  TRANSAPRENT: tokens.colorTransparentBackground,
  WHITE_TRANSPARENT: tokens.colorNeutralBackgroundAlpha2,
};

export const PincitesBackgroundImage = {
  WELCOME_PAGE: `linear-gradient(160deg, ${PincitesColor.BRAND_BLUE} 0%, ${PincitesColor.BRAND_BLUE_BACKGROUND} 100%)`,
};
